import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { pathConverter, iframePaths } from '../utils';
import styled from '@emotion/styled';

type IframeProps = {
  path: string;
};

export const Iframe = ({ path }: IframeProps) => {
  const [iframePath, setIframePath] = useState('');
  const [loading, setLoading] = useState(false);
  const hidden = !iframePaths.includes(path);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (iframePaths.includes(path) && iframePath !== path) {
      if (!iframePath) {
        setLoading(true);
      }
      setIframePath(path);
    }
  }, [path]);

  useEffect(() => {
    if (wrapperRef.current) {
      const debouncedHandleResize = debounce(entries => {
        for (const entry of entries) {
          setDimensions({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        }
      }, 200);
      const observer = new ResizeObserver(debouncedHandleResize);
      observer.observe(wrapperRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <IframeWrapper ref={wrapperRef} hidden={hidden || !iframePath}>
      {iframePath ? (
        <>
          <HidableIframe>
            <IframeEl
              title={path}
              id="iframe-old-spa"
              width={dimensions.width}
              height={dimensions.height}
              src={pathConverter(iframePath)}
              onLoad={() => {
                setLoading(false);
              }}
            />
          </HidableIframe>
          {loading ? <Loader>Loading iframe..</Loader> : null}
        </>
      ) : null}
    </IframeWrapper>
  );
};

const IframeEl = styled.iframe`
  border: none;
`;

const IframeWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: ${p => (p.hidden ? 'none' : 'flex')};
  width: 100%;
`;

const HidableIframe = styled.div`
  position: absolute;
  inset: 0;
`;

const Loader = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 50px;
  top: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
`;
