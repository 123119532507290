import { Avatar as MuiAvatar } from '@mui/material';
import type { AvatarProps } from './types';
import { useMemo } from 'react';
import { palette } from 'styles/theme/palette';

const getInitials = (letters?: string, size?: number) => {
  if (!letters) return '';

  const words = letters.split(' ');
  const firstWord = words[0];
  const lastWord = words[words.length - 1];

  const firstLetter = firstWord ? firstWord[0].toUpperCase() : '';
  const lastLetter = lastWord ? lastWord[0].toUpperCase() : '';

  if (size === 1 || words.length < 2) {
    return firstLetter;
  }

  return `${firstLetter}${lastLetter}`;
};

const sizeMap = {
  xs: 16,
  xsm: 20,
  xsl: 24,
  sm: 32,
  lg: 40,
  xl: 48,
  xxl: 56,
  xxxl: 72,
};

const fontSizeMap = {
  xs: 8,
  xsm: 10,
  xsl: 12,
  sm: 16,
  lg: 20,
  xl: 24,
  xxl: 28,
  xxxl: 36,
};

const maxLetterMap = {
  xs: 2,
  xsm: 2,
  xsl: 2,
  sm: 2,
  lg: 2,
  xl: 2,
  xxl: 2,
  xxxl: 2,
};

const colorMap = [
  palette.utility.marineBlue[400],
  palette.utility.lavender[800],
  palette.utility.coral[500],
  palette.utility.sunflower[700],
  palette.utility.brand[300],
  '#8A8DA4',
  '#E49CE9',
  '#B28481',
  '#FFCC65',
  '#63EBE1',
  '#4A538D',
  '#DD41E7',
  '#CF706C',
  '#887A5F',
  '#5B7E7B',
  '#5566E9',
];

export const Avatar = ({ sx, size = 'lg', src, letters, alt }: AvatarProps) => {
  const sxStyles = useMemo(
    () => ({
      width: sizeMap[size],
      height: sizeMap[size],
      fontSize: `${fontSizeMap[size]}px`,
      backgroundColor: colorMap[Math.floor(Math.random() * colorMap.length)],
      ...sx,
    }),
    [sx, size]
  );

  const slicedLetters = useMemo(() => getInitials(letters, maxLetterMap[size]), [letters, size]);

  return (
    <MuiAvatar data-testid="avatar" sx={sxStyles} alt={alt} src={src}>
      {letters ? slicedLetters : null}
    </MuiAvatar>
  );
};
