import { action, computed } from 'easy-peasy';
import { ModalManagerStore } from '../types';

export const modalManagerStore: ModalManagerStore = {
  activeId: '',
  modals: [],
  isModalListVisible: false,
  setModalListVisibility: action((state, payload) => {
    state.isModalListVisible = payload;
  }),
  setActiveId: action((state, payload) => {
    state.activeId = payload;
  }),
  add: action((state, payload) => {
    if (state.modals.filter(f => f.id === payload.id).length > 0) return;
    state.modals = [...state.modals, payload];
  }),
  remove: action((state, payload) => {
    if (state.modals.length === 1) state.isModalListVisible = false;
    state.modals = [...state.modals.filter(f => f.id !== payload)];
  }),
  clear: action(state => {
    state.modals = [];
  }),
  dontShowAgain: [],
  showAgain: computed(state => state.dontShowAgain.indexOf(state.activeId) > -1),
  setShowAgain: action((state, payload) => {
    if (payload) {
      state.dontShowAgain.push(state.activeId);
    } else {
      state.dontShowAgain = state.dontShowAgain.filter(f => f !== state.activeId);
    }
  }),
};
