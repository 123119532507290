import { useState } from 'react';
import styled from '@emotion/styled';
import { MenuItem } from './MenuItem';
import { Jargon, BaseButton, Tooltip, Image } from 'components';
import { IS_DEV } from 'config';
import {
  Package,
  ClipboardText,
  PuzzlePiece,
  Table,
  ChartBar,
  Receipt,
  Megaphone,
  Certificate,
  Gear,
  DevToLogo,
  Icon,
  SidebarSimple,
} from '@phosphor-icons/react';
import type { Children as JargonType } from 'components/jargon';
import { useLocation } from 'react-router-dom';
import { delay } from 'lodash';
import { t } from '@lingui/macro';
import DelogueLogo from 'img/delogue-logo.svg';

type MenuItems = {
  url: string;
  MenuIcon: Icon;
  text: JargonType;
  topPadding?: boolean;
}[];

const menuItems: MenuItems = [
  { url: '/taskboard', MenuIcon: ClipboardText, text: 'Taskboard' },
  { url: '/style', MenuIcon: Package, text: 'Styles' },
  { url: '/item', MenuIcon: PuzzlePiece, text: 'Items' },
  { url: '/report', MenuIcon: Table, text: 'Reports' },
  { url: '/analytics', MenuIcon: ChartBar, text: 'Analytics' },
  { url: '/order', MenuIcon: Receipt, text: 'Orders' },
  { url: '/marketing', MenuIcon: Megaphone, text: 'Marketing' },
  { url: '/compliance', MenuIcon: Certificate, text: 'Compliance' },
  { url: '/admin', MenuIcon: Gear, text: 'Admin', topPadding: true },
];

type SidebarProps = {
  children: React.ReactNode[];
};

export const Sidebar = ({ children }: SidebarProps) => {
  const [open, setOpen] = useState<'closed' | 'open' | 'fixed'>('closed');
  const location = useLocation();

  const onEnter = () => {
    if (open === 'closed') {
      setOpen('open');
    }
  };

  const onLeave = () => {
    if (open === 'open') {
      setOpen('closed');
    }
  };

  const onKeep = () => {
    if (open === 'open' || open === 'closed') {
      setOpen('fixed');
      return;
    }
    if (open === 'fixed') {
      setOpen('open');
      return;
    }
  };

  const onMenuClick = () => {
    if (open === 'open') {
      setOpen('closed');
    }
  };

  return (
    <Container fixed={open === 'fixed'}>
      <Menu
        onMouseEnter={() => delay(onEnter, 200)}
        onMouseLeave={() => delay(onLeave, 200)}
        open={open}
      >
        <LogoAndMenu>
          <LogoCon>
            <Image src={DelogueLogo} alt="Delogue logo" width={40} />
          </LogoCon>
          <MenuItemsContainer>
            {menuItems.map(({ url, MenuIcon, text, topPadding }) => (
              <MenuItem
                key={url}
                url={url}
                MenuIcon={MenuIcon}
                topPadding={topPadding}
                selected={location.pathname === url}
                onClick={() => onMenuClick()}
                extend={open !== 'closed'}
              >
                <Jargon text={text} />
              </MenuItem>
            ))}
            {IS_DEV ? (
              <MenuItem
                url="/example/playground"
                MenuIcon={DevToLogo}
                selected={location.pathname === '/example/playground'}
                onClick={() => onMenuClick()}
                extend={open !== 'closed'}
              >
                <Jargon text="Example" />
              </MenuItem>
            ) : null}
          </MenuItemsContainer>
        </LogoAndMenu>
        <Tooltip title={open === 'fixed' ? t`Collapse sidebar` : t`Keep sidebar`}>
          <BaseButton onClick={() => onKeep()}>
            <SidebarSimple weight={open === 'fixed' ? 'fill' : undefined} />
          </BaseButton>
        </Tooltip>
      </Menu>
      <TopAndContent>
        {children[0]}
        {children[1]}
      </TopAndContent>
    </Container>
  );
};

const TopAndContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

const LogoAndMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const LogoCon = styled.div`
  height: 96px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Container = styled.div<{ fixed: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  main {
    margin-left: ${p => (p.fixed ? 248 : 88)}px;
    z-index: 1;
  }
`;

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Menu = styled.nav<{ open: 'closed' | 'open' | 'fixed' }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${p => p.theme.palette.bg.primary};
  height: 100%;
  width: ${p => (p.open !== 'closed' ? '248px' : '88px')};
  transition: width 0.2s ease-in-out;
  justify-content: space-between;
  align-items: flex-end;
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;
`;
